.sidebar-container {
  width: 78px;
  padding-top: 8px;
  margin-right: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
}

.sidebar-logo {
  width: 59px;
  height: 23px;
  overflow: hidden;
  margin: 11px 9px 7px 10px;
}

.sidebar-nav-items {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding-top: 7px;
  padding-bottom: 14px;
  height: 100%;
  justify-content: space-between;
}
.sidebar-item {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.sidebar-item svg {
  fill: rgba(35, 36, 77, 0.5);
}

.sidebar-item-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar-item-list-container-mobile .sidebar-item {
  width: unset;
}

.sidebar-item-list-container-mobile {
  padding: 17.42px 7.75px 0 13px !important;
}

.sidebar-items-list-container-second-level .sidebar-item {
  padding-left: 30.49px !important;
}

.sidebar-item-list-container .sidebar-item {
  padding-left: 15px;
}

.sidebar-item-list {
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  border-bottom: 0.75px solid rgba(233, 233, 237, 1);
}

.sidebar-item-list-container-mobile > div:first-child {
  border-top: 0.75px solid rgba(233, 233, 237, 1);
}

.sidebar-item-list .sidebar-item svg {
  width: 1em !important;
}

.sidebar-item-list .sidebar-item {
  margin: 0;
}

.sidebar-item-list .sidebar-item:hover {
  background-color: initial !important;
}

.sidebar-item-list:hover > .sidebar-item-list-title,
.sidebar-item-list:hover > .sidebar-item svg {
  color: #00d03b;
  fill: #00d03b !important;
}
.sidebar-item-list:hover {
  background-color: initial !important;
}

.sidebar-item-list-title {
  margin-left: 12.19px;
  color: rgba(145, 145, 166, 1);
  font-size: 12px;
}

.sidebar-item-show-more-button {
  background-color: rgba(145, 145, 166, 0.1);
  width: 18.75px;
  height: 18.75px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 10.25px;
  cursor: pointer;
}

.sidebar-item-show-more-button:hover {
  opacity: 0.8;
}

.sidebar-item-dropdown-menu-second-level {
  left: 194px !important;
  width: 171px !important;
}

.sidebar-item-dropdown-menu-container {
  position: relative;
  width: calc(100% + 379px);
  height: 100%;
  margin-left: calc(53px + 379px);
  z-index: 1001;
}

.sidebar-item-dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  /* left: 87px; */
  width: 185px;
  background-color: white;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.sidebar-item-dropdown-menu .sidebar-item {
  width: initial;
  margin-left: 16.06px;
}

.sidebar-item-dropdown-menu .sidebar-item svg {
  width: 1.4em;
  height: 1.4em;
}

.sidebar-item.bottom-menu-item {
  margin-top: 13px;
}

.sidebar-item-list-active .sidebar-item-list-title {
  color: #00d03b;
}

.sidebar-item-active,
.sidebar-item:hover {
  cursor: pointer;
  background-color: rgba(0, 208, 59, 0.1) !important;
}

.sidebar-item-list-item:hover {
  cursor: pointer;
}

.sidebar-item-list-item:hover > .sidebar-item-list-title {
  cursor: pointer;
  color: #00d03b;
  opacity: 0.7;
}

.sidebar-item-list-item:hover > .sidebar-item > svg,
.sidebar-item-list-active > svg,
.sidebar-item-active > svg,
.sidebar-item:hover > svg {
  fill: #00d03b;
}

.sidebar-image-icon-active {
  border-radius: 25px;
  border: 3px solid rgb(213, 213, 218);
}

.sidebar-item-list-container-mobile .sidebar-item-list-title {
  font-size: 16px !important;
  font-weight: 500;
}

.sidebar-image-icon:hover {
  opacity: 0.8;
}

.default-profile-icon-bg {
  background-image: url("./../assets//icons/profile-image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.show {
  display: flex;
}

.hide {
  display: none;
}
