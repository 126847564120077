@font-face {
  font-family: "Nunito Sans Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Sans Regular"), local("Nunito Sans-Regular"),
    url("./assets/fonts/NunitoSans-Regular.ttf") format("truetype"),
    url("./assets/fonts/NunitoSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/NunitoSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Nunito Sans Bold";
  font-style: normal;
  font-weight: 800;
  src: local("Nunito Sans Bold"), local("Nunito Sans-Bold"),
    url("./assets/fonts/NunitoSans-Bold.ttf") format("truetype");
}

.App {
  /*text-align: center;*/
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  display: flex;
  min-height: 100vh;
}

.container-content {
  /* Width depends on width of sidebar*/
  width: calc(100% - 89px);
  display: flex;
  flex-direction: column;
  /* Margin depends on widht of sidebar + space between sidebar and content*/
  margin-left: 89px;
}

.container.container-login-register-page .container-content {
  justify-content: center;
}

.resizePicture {
  justify-content: center;
  width: 100%;
  height: auto;
}
nav ul {
  margin: 0;
}
/* FORMS */
.form-container {
  max-width: 300px;
  background-color: #ffffff;
  padding: 27px 58px 64px;
  padding-bottom: 16px;
  border-radius: 10px;
  box-shadow: 0px 6px 46px rgba(35, 36, 77, 0.1);
  margin: 0 auto;
}
.form-container form {
  margin-bottom: 20px;
}
.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
}
.form-control label {
  text-align: left;
  color: #7d839a;
  font-weight: bold;
  font-size: 13px;
  font-weight: 900;
  margin-left: 8px;
  position: absolute;
  background-color: #ffffff;
  top: -11px;
  z-index: 1000;
  padding: 0 3px;
}
.form-control input {
  width: 100%;
  padding: 8px 0 8px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #9191a6;
  border-color: rgba(145, 145, 166, 0.2);
  border-radius: 4px;
  outline-color: #d9d9d9;
  font-size: 13px;
}
.form-control input:disabled {
  font-weight: 600;
}
input {
  box-sizing: border-box;
}
/* checkbox */
input[type="checkbox"] {
  position: relative;
  width: 1em;
  height: 1em;
  color: black;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: "background 175ms cubic-bezier(0.1, 0.1, 0.25, 1)";
  overflow: hidden;
}
input[type="checkbox"]::before {
  position: absolute;
  content: "";
  display: block;
  top: 0px;
  left: 4px;
  width: 2px;
  height: 7px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
input[type="checkbox"]:checked {
  color: #ffffff;
  border-color: #00b33c;
  background: #00b33c;
}
input[type="checkbox"]:checked::before {
  opacity: 1;
}
input[type="checkbox"]:checked ~ label::before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* Password */
input[type="password"] {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 4px;
}

input[type="password"]:disabled {
  font-size: 17px;
  font-weight: 800;
  line-height: 23px;
}

.form-container .form-message {
  font-size: 12px;
  color: rgba(255, 75, 71, 1);
}

.form-container .form-control .form-message {
  font-size: 11px;
  margin-top: 5px;
  margin-left: 5px;
}

.form-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8em;
  margin-top: 25px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.icon-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 400px) {
  .form-options {
    justify-content: center;
  }
  .checkbox-container {
    margin-bottom: 10px;
  }

  .form-container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.form-container .link:hover {
  text-decoration: underline;
}

.form-header {
  font-size: 24px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 30px;
}
.btn {
  width: 100%;
  padding: 9.5px;
  background-color: #02ba36;
  border-radius: 4px;
  border: 0;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 22px;
  outline-color: #009933;
}

.btn:hover {
  opacity: 0.8;
}

.btn.btn-outline {
  background-color: transparent;
  border: 2px solid #00d03b;
  border-radius: 10px;
  color: #00d03b;
  text-transform: none;
  font-size: 12px;
  outline: none;
  padding: 7px 19px 8px 19px;
  margin-top: 0;
  margin-bottom: 10px;
  margin-right: 19px;
}

.btn.btn-white {
  background-color: #ffffff;
  padding: 7px 18px 8px 12px;
  color: initial;
  margin-top: 0;
  margin-bottom: 11px;
  margin-right: 13px;
  outline: none;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 800;
}

.btn.logout svg {
  fill: #00d03b;
  width: 35px;
  height: 15px;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.text-green {
  color: #00b33c;
  font-weight: bold;
  font-size: 0.8rem;
}
.cursor-pointer {
  cursor: pointer;
}
.margin-top {
  margin-top: 20px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
  font-weight: 500;
  background-color: transparent;
}

.bg {
  position: absolute;
  z-index: -1000;
  background-image: url("./assets/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.cnqr-logo {
 /*background: url("./assets/icons/cnqr-logo.svg");*/ 
 background: url("./assets/icons/newlogo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 193px;
  width: 100%;
  height: 75px;
  margin: 0 auto 54px auto;
}

@media screen and (max-width: 500px) {
  .cnqr-logo {
    margin-top: 25px;
  }

  .title-container {
    flex-wrap: wrap;
  }
}
.datablock-vt .data-block-ndcs {
  display: none;
}

.datablock-vt .data-block-visits {
  display: flex;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  color: #3dadd0;
  font-family: "Nunito Sans Bold";
  padding-bottom: 5%;
}

.datablock-nt .data-block-visits {
  display: none;
}

.datablock-nt .data-block-ndcs {
  display: flex;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  color: #3dadd0;
  font-family: "Nunito Sans Bold";
  padding-bottom: 5%;
}

.barblock-nt .chartGrid,
.barblock-vt .chartGrid {
  height: 100%;
}

.chart-container {
  flex: 1 1 1%;
  background-color: white;
  margin-right: 12px;
  border-radius: 10px;
  border-right: 1px solid rgba(145, 145, 166, 0.2);
}

.chart-container:last-child {
  /*border-right: none;*/
}

.chart-container svg {
  width: 100%;
}

.chart-container.table {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 11px;
  border-top: 1px solid rgba(35, 36, 77, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.chart-container.chart-container-atl p {
  font-size: 13px;
  font-weight: 400;
}

@media screen and (max-width: 1234px) {
  .chart-container.chart-container-atl:first-child {
    margin-right: 13px !important;
    border-top-right-radius: 10px !important;
  }
  .chart-container.chart-container-atl:last-child {
    border-top-right-radius: 0;
  }
  .top-charts-container-atl {
    flex-direction: column !important;
  }
  .legend-wrapper {
    margin: 0 10px;
  }
}

@media screen and (max-width: 683px) {
  .atl-tv-top-container {
    flex-direction: column;
    /*overflow: hidden;*/
  }
  /* .donut-container {
    margin-right: 0 !important;
  } */
}

@media screen and (max-width: 468px) {
  .donut-container {
    /* width: 40vw !important; */
    transition: width 0.3s;
  }
}

.chart-container.chart-container-atl:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}

.chart-container.chart-container-atl:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 13px;
}

.chart-container-atl .graph-container {
  border: 0;
  /* height: 140px; */
  font-size: 9px;
  padding-bottom: 37px;
}

.chart-container-atl .donut-container {
  margin-right: 66.24px;
  margin-left: 41.9px;
  padding-left: 0;
  height: 140px;
}

/* .chart-container-atl .donut-container svg {
  max-width: 136.2px;
  max-height: 137.28px;
} */

.chart-container-atl .chart-left-description {
  max-width: 74.61px;
  max-height: 36.79px;
  font-size: 14px;
  margin-left: 20px;
}

.chart-container-atl .chart-left-description,
.chart-container-atl .legend-wrapper {
  font-weight: 350;
  white-space: nowrap;
}

@media screen and (max-width: 914px) {
  .chart-container-atl .chart-left-description,
  .chart-container-atl .chart-right-description {
    margin-left: 20px;
  }
}

.chart-container-atl .legend-wrapper {
  margin-left: 0;
}

.chart-container-atl .chart-title {
  border-bottom: 1px solid rgba(35, 36, 77, 0.1);
}

.chart-container-comp:first-child {
  border-right: 1px solid rgba(35, 36, 77, 0.1);
}

.chart-container-comp .grid-item {
  display: flex;
}

@media screen and (max-width: 968px) {
  .top-charts-container {
    flex-direction: column !important;
  }
  .bottom-charts-container {
    flex-direction: column !important;
  }
  .chart-container.bottom-container {
    margin-bottom: 11px;
  }
  .bottom-charts-total-blocks {
    flex-direction: row !important;
  }
  .react-datepicker-wrapper {
    /*margin-right: 15px;*/
  }
  .top-container {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .overview .small-container {
    min-width: unset !important;
  }
  .bottom-charts-total-blocks {
    flex-direction: column !important;
  }
}

.small-container {
  margin: 0px 14px 10px 0;
}

.overview .small-container {
  min-width: 225px;
}

@media screen and (min-width: 1100px) {
  .overview .small-container {
    min-width: 411px;
  }
}

.bottom-container {
  margin-right: 13px;
}

.top-container {
  padding-bottom: 20px;
  overflow: hidden;
}

.chart-title {
  text-align: left;
  margin: 0px;
  padding: 13px 0px 12px 20px;
  font-size: 1em;
  color: #23244d;
  font-family: "Nunito Sans Bold";
  line-height: 19px;
  font-size: 14px;
  font-weight: 800;
}

.chart-title.table {
  font-size: 14px;
  font-weight: 800;
  padding: 12px 0 18px 19px;
}

.chart-title.table {
  font-size: 14px;
  font-weight: 800;
  padding: 11px 0 13px 19px;
}

button[class*="example-custom-input-"] {
  height: 31px;
  width: 130px;
  font-family: "Nunito Sans Regular";
  font-size: 12px;
  line-height: 16.37px;
  color: var(--datepicker-text-color);
  background-color: var(--datepicker-background);
  border: none;
  border-radius: 10px;
  margin-right: 6px;
  margin-bottom: 11px;
}

.example-custom-input__date-range {
  background-color: var(--datepicker-background);
}

button[class*="example-custom-input-"] span {
  opacity: 0.5;
}

.page {
  margin: 15px;
  border-radius: 30px;
  background: #ffffff;
}

.article {
  padding: 45px 100px 35px 35px;
}

.article h1 {
  margin: 0px 0px 25px 0px;
}

.article h2 {
  color: #656682;
  margin: 0px 0px 40px 0px;
}

.article-teaser {
  margin: 0px -100px 0px -35px;
  padding: 0px 100px 30px 35px;
  border-bottom: 2px solid #e5e5e5;
}

.article .article-teaser {
  font-size: 18px;
}

.article .article-teaser h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.article h4 {
  margin: 35px 0px 12px 0px;
  font-size: 18px;
}

.article article {
  color: #5a5b7a;
  line-height: 24px;
}

.article .article-teaser article {
  line-height: 26px;
}

.article h4,
article h3,
article h1 {
  color: #23244d;
}

.article article a {
  color: #02ba36;
  text-decoration: none;
  font-weight: 600;
}

@media (max-width: 576px) {
  .article {
    padding: 25px;
  }

  .article h1 {
    font-size: 26px;
  }

  .article h2 {
    font-size: 22px;
  }

  .article-teaser {
    margin-right: -25px;
    padding-right: 25px;
  }
  .form-component {
    /* padding: 0px 25px 30px 25px !important; */
  }
  .form-fields {
    margin: 0px -25px 0px -25px !important;
    padding: 10px 25px 0px 25px !important;
  }
}

.form-component {
  padding: 10px 23px 40px 43px;
  background: #fff;
  margin: 15px 123px 0px 15px;
  border-radius: 10px;
  max-width: 964px;
}

.form-button-holder .cancel {
  border-radius: 5px;
  font-size: 13px;
  font-weight: 900;
  color: #02ba36;
  background: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  margin-left: 38px;
}

.form-button-holder .save {
  margin-left: 5px;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 900;
  color: #fff;
  background: #02ba36;
  cursor: pointer;
  border: none;
  outline: none;
  text-transform: uppercase;
  margin-left: 22px;
}

.form-button-holder button:hover {
  opacity: 0.8;
}

.form-row span {
  width: 100%;
  min-width: 50px;
  padding-right: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #180b2b;
}

.form-row .input-holder {
  width: 100%;
  position: relative;
  display: inline-block;
}

@media (min-width: 860px) {
  .form-values {
    padding-top: 15px;
  }

  .form-row span {
    width: 25%;
    font-size: 17px;
    display: inline-block;
    font-weight: bold;
  }

  .form-row .input-holder {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-right: 147px;
  }
}

.input-holder input {
  font-size: 13px;
  font-weight: 600;
  border: 2px solid #f4f4f6;
  color: #23244d;
  width: 100px;
}

.input-holder input::placeholder {
  color: #23244d;
}

.form-row .input-holder input {
  width: 100%;
  padding: 8px 0 8px 18px;
}

.form-row .input-holder label {
  text-align: left;
  color: #7d839a;
  font-weight: 900;
  font-size: 13px;
  margin-left: 15px;
  position: absolute;
  background-color: #ffffff;
  top: -10px;
  z-index: 1000;
  padding: 0 4px;
  white-space: nowrap;
}

.form-row {
  margin-top: 21px;
  display: flex;
  align-items: center;
}

.form-component input {
  outline: none;
}

h2.company-name {
  color: #180b2b;
  padding-bottom: 23px;
}

.form-fields {
  margin: 0px -45px 0px -45px;
  padding: 10px 23px 0 45px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.form-fields h2 {
  margin: 0;
}

.form-button-holder {
  /* padding-bottom: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 510px) {
  .form-fields {
    flex-direction: row;
  }

  .form-button-holder {
    justify-content: flex-end;
    padding-bottom: 0px;
  }
}

.form-company-holder {
  flex: 3;
  position: relative;
}

.company-field-name {
  position: absolute;
  left: 7px;
  padding: 0px 3px;
  font-size: 13px;
  background: #fff;
  color: #7d839a;
}

button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-form-button {
  border-radius: 28px;
  width: 41px;
  height: 41px;
  background-color: rgba(2, 186, 54, 0.15);
  border: 0;
  outline: none;
}

.margin-0 {
  margin: 0;
}

.form-component .company-heading {
  width: 100%;
}

.form-row input:disabled {
  background: transparent;
  border: 0;
  color: #7d839a;
  font-size: 18px;
  font-weight: 500;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 18px;
  margin-top: 13px;
}

.title {
  font-family: "Nunito Sans Bold";
  font-size: 20px;
  font-weight: 800;
  line-height: 27.28px;
  margin: 2px 0px;
  margin-bottom: 13px;
}

.clear-date {
  background-color: white;
  border: none;
}

.react-datepicker {
  border: none !important;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.react-datepicker__header {
  background-color: rgba(0, 208, 59, 0.1) !important;
  color: #00d03b !important;
  border: none !important;
  height: 26px;
}

.react-datepicker-popper {
  margin-top: 0px !important;
}

.react-datepicker__monthPicker {
  display: flex;
  flex-direction: column;
  /* height: 124px; */
  justify-content: space-around;
}

.react-datepicker__month-text {
  color: #23244d !important;
  opacity: 0.5 !important;
  font-family: "Nunito Sans Regular";
  font-size: 13px;
  line-height: 26px;
}

.react-datepicker__close-icon {
  display: none !important;
}

.react-datepicker__month-text {
  height: 24px !important;
  width: 49px !important;
  margin: 2px 5px !important;
}

.react-datepicker__month--selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #00d03b !important;
  border-radius: 10px !important;
  color: white !important;
  font-weight: "Nunito Sans Regular";
  opacity: 1 !important;
  height: 24px !important;
  width: 49px !important;
}

.digital .react-datepicker__month {
  padding-top: 25px;
}

.react-datepicker__day--in-range,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--keyboard-selected {
  background-color: #00d03b !important;
  border-radius: 10px !important;
  color: white !important;
  font-weight: "Nunito Sans Regular";
  /* width: 29px !important; */
  outline: none;
  opacity: 1 !important;
}

.react-datepicker__day {
  opacity: 0.5;
}

.react-datepicker__day--in-range {
  background-color: #00d03b !important;
}

.react-datepicker__current-month {
  color: #00d03b !important;
}

.react-datepicker__day--in-selecting-range {
  opacity: 0.5 !important;
}

.react-datepicker__day--selected {
  opacity: 1;
}

.digital .react-datepicker__day-names {
  padding-top: 10px;
  opacity: 0.6;
}

.react-datepicker__navigation--previous {
  border-right-color: #00d03b !important;
}

.react-datepicker__navigation--next {
  border-left-color: #00d03b !important;
}

.react-datepicker-wrapper {
  display: inline;
}

.back-to-overview-link {
  display: flex;
  align-items: center;
  color: #02ba36;
  text-align: left;
  font-size: 14px;
  font-weight: 800;
  margin-top: 21px;
  margin-bottom: 23px;
}

.back-to-overview-link:hover {
  opacity: 0.8;
  cursor: pointer;
  text-decoration: underline;
}

.back-to-overview-link,
.form-component {
  margin-left: 112px;
}

.react-datepicker-wrapper {
  position: absolute;
  top: 14px;
  right: 83px;
  width: 116px;
  height: 31px;
}

.atl .react-datepicker-wrapper {
  right: 83px;
}

.table-container-header .react-datepicker-wrapper {
  position: relative;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 121px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tv-channels-first-date-picker button[class*="example-custom-input-"],
.tv-channels-second-date-picker button[class*="example-custom-input-"] {
  width: 87px;
  height: 31px;
  min-height: 31px;
  white-space: nowrap;
  background-color: #f1f2f5 !important;
  border-radius: 10px;
  font-size: 12px;
}

.data-filter {
  /*z-index: 1001;*/
}

.data-filter.data-filter-competitors,
.data-filter.data-filter-competitors div[class*="control"] {
  position: absolute;
  top: 7px;
  right: 37px;
  background-color: white;
}

.competitors-date-picker {
  margin-right: 120px;
}

.data-filter div[class*="control"] {
  position: absolute;
  top: 6px;
  right: 5px;
}

.table-container {
  max-height: 407px;
  overflow-y: auto;
}

.chart-container-fully-rounded.chart-container.table {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 0;
}
@keyframes slide {
  from {
    display: none;
  }
  to {
    display: flex;
  }
}

.burger-menu {
  background-color: white;
  position: fixed;
  z-index: 1010;
  box-shadow: 0px 6px 46px rgba(35, 36, 77, 0.3);
  min-height: 100%;
  z-index: 1010;
}

.burger-menu-content {
  padding: 17px 15px 0 15px;
}

.burger-menu-logo {
}

.burger-menu-button {
  position: absolute;
  top: 18px;
  cursor: pointer;
  z-index: 1010;
  color: rgba(35, 36, 77, 1);
}

.burger-menu-button svg {
  fill: rgba(35, 36, 77, 0.5);
}

.burger-menu-button:hover svg {
  fill: #00d03b;
}

.nav-items-mobile .sidebar-item {
  width: 36px;
  height: 36px;
}

.burger-menu ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  /* margin: 0 */
}

.burger-menu ul li {
  display: flex;
  justify-content: center;
  flex: 1 1 30%;
  margin: 3px;
}

.burger-menu li .sidebar-item {
  align-items: center;
}

.close-button {
  display: flex;
  /* justify-content: flex-end; */
  /* margin-top: 5px;
  margin-right: 5px; */
  cursor: pointer;
}

.close-button:hover {
  opacity: 0.5;
}

.open-mobile-menu-button {
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  .charts-container-timezone2 {
    grid-template-columns: auto auto !important;
  }
}

@media screen and (max-width: 630px) {
  .charts-container-timezone2 {
    grid-template-columns: auto !important;
  }
}

@media screen and (max-width: 1300px) {
  .charts-container-channels2 {
    grid-template-columns: auto !important;
  }
}

.nav-items-mobile {
  left: 200px;
  display: none;
  z-index: 1001;
}

/*
.competitors-1 .title-container {
  display: inline-block;
}

.competitors-1 .react-datepicker-wrapper {
  position: unset;
  display: inline-block;
  right: unset;
  top: unset;
  padding-right: 11px;
  padding-top: 13px;
  float: none;
}

.competitors-1 .filter-competitors1 {
  align-self: unset;
  display: inline-block;
}
*/

.atl button[class*="example-custom-input-"] {
  background-color: white;
}

/*
.competitors-1 div[class*="control"] {
  background-color: white;
}
*/

/*
.competitors-1 .page-content-container {
  display: grid;
  grid-template-columns: auto 6% 6%;
}

.competitors-1 .charts-wrapper {
  grid-column: 1 / 4;
  grid-row: 2;
}

.competitors-1 .react-datepicker__tab-loop {
  /* position: absolute; 
}
*/

div[class^="BarChart"] {
  padding-left: 19px;
  padding-right: 22px;
}

.timezone2 .timezone2-date-picker {
  position: unset;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 10px;
}

.timezone2 .timezone2-date-picker button[class*=".example-custom-input-"] {
  width: 111px;
}

.digital .data-filter:first-child div[class*="-control"],
.digital .data-filter:first-child div[class*="-menu"] {
  right: 390px;
}

.digital .data-filter:nth-child(2) div[class*="-control"],
.digital .data-filter:nth-child(2) div[class*="-menu"] {
  right: 260px;
}

.digital .data-filter:nth-child(3) div[class*="-control"],
.digital .data-filter:nth-child(3) div[class*="-menu"] {
  right: 130px;
}

/* @media screen and (max-width: 648px) {
  .digital .data-filter div[class*="-control"],
  .digital .data-filter div[class*="-menu"] {
    top: 35px;
  }
} */

@media screen and (max-width: 646px) {
  .digital .data-filter:first-child div[class*="-control"],
  .digital .data-filter:first-child div[class*="-menu"] {
    top: 40px;
    right: 5px;
  }
}

@media screen and (max-width: 518px) {
  .digital .data-filter:nth-child(2) div[class*="-control"],
  .digital .data-filter:nth-child(2) div[class*="-menu"] {
    top: 40px;
    right: 130px;
  }
}

/* @media screen and (max-width: 600px) {
  .digital .data-filter:first-child div[class*='-control'] {
    right: 165px;
  }
  
  .digital .data-filter:nth-child(2) div[class*='-control'] {
    right: 85px;
  }
} */

/* .digital .chart-1 {
  transform: translate(1.75%, 1%);
} */
@media screen and (max-width: 856px) {
  .new-entry-alert .title-container {
    padding-bottom: 40px;
  }
  .new-entry-alert .react-datepicker-wrapper {
    position: absolute;
    left: 91px;
    top: 50px;
  }
  .new-entry-alert .data-filter,
  .new-entry-alert .data-filter div[class*="control"] {
    top: 25px;
    left: 104px;
  }
  .pdf-button-container {
    position: absolute;
    top: 50px;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .sidebar-container {
    display: none !important;
  }
  .container-content {
    margin-left: 11px;
    width: 100%;
  }
  .nav-items-mobile {
    display: flex !important;
  }
  .burger-menu-button {
    display: block !important;
  }

  .form-component {
    margin-left: 5px;
    margin-right: 10px;
  }

  .back-to-overview-link {
    margin-left: 5px;
  }

  .new-entry-alert .title-container {
    padding-bottom: 40px;
  }
  .new-entry-alert .react-datepicker-wrapper {
    position: absolute;
    left: 11px;
  }
  .new-entry-alert .data-filter,
  .new-entry-alert .data-filter div[class*="control"] {
    left: 64px;
  }
  .pdf-button-container {
    position: absolute;
  }
}

@media screen and (max-width: 442px) {
  .new-entry-alert .title-container {
    padding-bottom: 60px;
  }
  .new-entry-alert .react-datepicker-wrapper {
    position: absolute;
    top: 90px;
  }
  .new-entry-alert .data-filter,
  .new-entry-alert .data-filter div[class*="control"] {
    top: 45px;
  }
  .pdf-button-container {
    position: absolute;
    top: 90px;
  }
}

.new-entry-alert .title-container {
  margin-bottom: 11px;
}

@media screen and (max-width: 728px) {
  .digital-tv .top-charts-container {
    flex-direction: column;
  }
}

.digital-tv .top-charts-container .top-container {
  margin-top: 11px;
}

.digital-tv .top-charts-container .top-container:first-child {
  margin-top: 0;
}

@media screen and (max-width: 1076px) {
  .charts-container-radio {
    grid-template-columns: auto !important;
  }
}
