table {
  width: 100%;
  min-width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.atl-tv .table-container {
  height: calc(100vh / 1.54);
  overflow-y: auto;
}

.timezone .table-container {
  height: calc(100vh / 2.6);
}

.atl-channels .table-container,
.competitors-1 .table-container {
  height: calc(100vh - 100vh / 1.6);
  overflow-y: auto;
}

@media screen and (max-width: 1231px) {
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
}

table img {
  max-width: 267px;
  height: 100%;
}

table th tr {
  font-size: 12px;
  font-weight: 900;
}

td {
  border-top: 1px solid lightgray;
  height: 13px;
  font-size: 13px;
  font-weight: 300;
  white-space: nowrap;
}

th {
  font-size: 12px;
  font-weight: 900;
}

th,
td {
  padding: 13px;
}
td:last-child {
  border-bottom: 1px solid lightgray;
}

td,
tr,
th {
  border-left-style: none;
  border-right-style: none;
}

table tbody tr > * {
  display: block;
}
table tbody tr {
  display: table-cell;
}

.number {
  /*font-weight: bold;*/
}

.cell-with-bar {
  display: flex;
  align-items: flex-start;
}

.cell-with-bar {
  text-align: right;
}

.text-align-right {
  text-align: right;
}

.bar-container {
  width: 90px;
  height: 5px;
  margin-left: 16px;
  margin-right: 5px;
  overflow: hidden;
}

.bar-numeric {
  width: 30px;
}

.bar-numeric-big-number {
  width: 70px;
}

.bar-value {
  height: 100%;
}

.bar-container,
.bar-value {
  border-radius: 25px;
}

thead tr th:first-child {
  padding-left: 19px;
}

thead tr th:last-child {
  padding-right: 17px;
}

.table-data-value {
  /* padding-left: 10px;*/
  padding-right: 10px;
}

.table tbody tr:first-child td {
  padding-left: 20px;
}

.new-entry-alert .table-container {
  max-height: 100%;
  height: 100%;
}

@media screen and (max-width: 1010px) {
  .new-entry-alert .table-container {
    height: calc(100vh - 122px);
    overflow-y: scroll;
  }
}

.table-row-with-image td {
  height: 154px;
}

.table-link:hover {
  text-decoration: underline !important;
}

.table-column-text-wrap {
  white-space: initial;
}
