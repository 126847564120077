@import url("variables.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #23244d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tooltip,
#pieTooltip {
  margin-top: -30px;
  padding: 12px 10px;
  position: absolute;
  text-align: center;
  min-width: 28px;
  max-height: 30px;
  border: none;
  border-radius: 12px;
  font: 12px sans-serif;
  background: var(--tooltip-bg-color);
  color: var(--tooltip-text-color);
  pointer-events: none;
}

a.tooltip:after,
#pieTooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 18px;
  border-width: 7px;
  border-style: solid;
  border-color: var(--tooltip-bg-color) transparent transparent transparent;
}

.grid-lines .tick line {
  stroke: var(--border-color);
  stroke-width: 1px;
}

.yAxis .domain,
.yAxis .tick line,
g[class*="xAxis"] .tick line,
g[class*="xAxisAngle"] .domain {
  stroke-width: 1px;
  stroke: var(--border-color);
}

.grid-lines .domain {
  display: none;
}

.yAxis .tick line {
  display: none;
}

g[class*="xAxis"] .tick line,
g[class*="xAxis"] .domain,
g[class*="xAxis"] .tick line {
  display: none;
}

g[class*="xAxisAngle"] .tick line,
g[class*="xAxisAngle"] .domain {
  display: none;
}

g[class*="xAxis"] .tick text,
g[class*="xAxis"] .tick text {
  color: var(--axis-text-color);
  font-family: "Nunito Sans Regular";
  font-size: 14px;
  line-height: 13.64px;
}
g[class*="yAxis"] .tick text {
  color: var(--axis-text-color);
  opacity: 0.5;
  font-family: "Nunito Sans Regular";
  font-size: 10px;
  line-height: 13.64px;
}
.yAxis .domain {
  display: none;
}

g[class*="xAxisAngle"] .tick text,
g[class*="xAxisAngle"] .tick text {
  transform: translate(-25px, 15px) rotate(-45deg);
  color: var(--axis-text-color);
  opacity: 0.5;
  font-family: "Nunito Sans Regular";
  font-size: 10px;
  line-height: 13.64px;
}

.bar {
  opacity: 0.9;
}

.arc text {
  color: var(--pie-text-color);
}

.react-datepicker__input-container {
  display: flex !important;
  justify-content: flex-end;
}

.react-datepicker__input-container input {
  padding: 10px;
  background-color: var(--datepicker-background);
  color: var(--datepicker-text-color);
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  font-size: 18px;
}

.pie-h1 {
  flex: 6;
  text-align: left;
}

.pie-h1 h1 {
  margin: 0;
  padding-left: 20px;
}

.react-datepicker-wrapper {
  flex: 1;
  float: right;
}

.graph-container {
  padding: 20px;
  padding-top: 50px;
  border-right: 2px solid var(--border-color);
}

.react-datepicker__close-icon {
  padding: 2px 6px !important;
  position: inherit !important;
  margin-left: 10px;
  background-color: var(--datepicker-background) !important;
  border-radius: 50%;
}

.react-datepicker__close-icon::after {
  font-size: 14px !important;
}

.donut-container {
  padding-left: 25px;
}
