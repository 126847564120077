:root.light {
  --tooltip-text-color: #c8c8d2;
  --tooltip-bg-color: #23244d;
  --border-color: #e9e9ed;
  --datepicker-background: #ffffff;
  --datepicker-text-color: #23244D;
  --axis-text-color: #23244D;
  --pie-text-color: #2a3153;
}

@font-face {
  font-family: 'Nunito Sans Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Sans Regular'), local('Nunito Sans-Regular'),
   url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype'),
   url('./assets/fonts/NunitoSans-Regular.woff2') format('woff2'),
   url('./assets/fonts/NunitoSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Nunito Sans Bold';
  font-style: normal;
  font-weight: 800;
  src: local('Nunito Sans Bold'), local('Nunito Sans-Bold'),
   url('./assets/fonts/NunitoSans-Black.ttf') format('truetype');
}
